import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import background from "../assets/AlfaBg.png";
import alfaLogo from "../assets/alfa-logo.svg";
import bullionStats from "../assets/bullioStats.png";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import usFlag from "../assets/usFlag.png";
import uaeFlag from "../assets/uaeFlag.png";
import indFlag from "../assets/indFlag.png";
import euroFlag from "../assets/euroFlag.png";
import gbpFlag from "../assets/gbpFlag.png";
import sgdFlag from "../assets/sgdFlag.png";
import { Box, Fade, Modal, Typography, useMediaQuery } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Marquee from "react-fast-marquee";
import "../App.css";
import moment from "moment-timezone";
import Carousel from "react-material-ui-carousel";
import io from "socket.io-client";
import {
  allCommodities,
  allNewses,
  findBanners,
  getConversionValue,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import Cookies from "js-cookie";
import { loginChecking, logoutTv } from "../sevice/login";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import LimitExceededModal from "./LimitExceededModal";
import LogoutIcon from "@mui/icons-material/Logout";
import SubscriptionExpired from "./SubscriptionExpired";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon";
const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  mainContainer: {
    width: "100%",
    // maxWidth: "2300px",
    height: "100vh",
    margin: "auto",
    padding: "1rem 5rem 2rem 5rem ",
    boxSizing: "border-box",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "15vh",
  },
  alfaLogoImg: {
    width: "35%",
    height: "100%",
  },
  body: {
    width: "100%",
    height: "75vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "2fr 1.4fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
      'leftPart rightPart'
    `,
    padding: "1rem 0 1rem 0",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    // gridTemplateRows: "3fr 1fr",
    gridTemplateRows: "52vh 20vh",
    gridTemplateAreas: `
      'table' 
      'lowerPart'
    `,
    // boxSizing: "border-box",
  },
  table: {
    gridArea: "table",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 6fr",
    gridTemplateAreas: `
      'tabeHeader' 
      'tableContent'
    `,
    gap: "1em",
    border: "1px solid #E3E3E3",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    boxSizing: "border-box",
    height: "100%",
  },
  tableHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#16341B",
  },
  tableContent: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar
    },
    gridArea: "tableContent",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    overflowX: "scroll",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "flex-start",
    fontSize: "2vw",
    paddingLeft: "2rem",
  },
  tableRow1: {
    flexBasis: 0,
    flexGrow: 1,
    minHeight: "15%",
    gridArea: "tableRow1",
    display: "flex",
    marginLeft: "1rem",
    marginRight: "1rem",
    borderBottom: "0.5px solid #E3E3E3",
  },
  lowerPart: {
    gridArea: "lowerPart",
    display: "grid",
    gridTemplateRows: "1fr",
    gridAutoColumns: "1fr 1fr",
    gridTemplateAreas: `
      'stats banner'
    `,
    columnGap: "1rem",
    boxSizing: "border-box",
    padding: "1rem 0 0 0",
  },
  stats: {
    gridArea: "stats",
    border: "1px solid #E3E3E3",
    borderRadius: "20px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "18vh",
  },
  bullionStatsImg: {
    width: "150px",
    height: "auto",
  },
  banner: {
    gridArea: "banner",
    borderRadius: "20px",
    border: "1px solid #E3E3E3",
    height: "18vh",
  },
  bannerImg: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "cover",
    overflow: "hidden",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "34vh 38vh",
    gridTemplateAreas: `
      'spotRate' 
      'currencyConverter'
    `,
  },
  spotRate: {
    gridArea: "spotRate",
    display: "flex",
    flexDirection: "column",
    color: "#fff",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  currencyConverter: {
    gridArea: "currencyConverter",
    border: "1px solid #E3E3E3",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  updates: {
    gridArea: "updates",
    height: "6vh",
    display: "grid",
    gridTemplateRows: "1fr",
    gridAutoColumns: "1.2fr 6fr",
    gridTemplateAreas: `
      'updatesHeader updatesContent'
    `,
    borderRadius: "20px",
    border: "0.5px solid #E3E3E3",
    background:
      "linear-gradient(180.14deg, rgba(141, 78, 75, 0.5) 18.62%, rgba(70, 35, 34, 0.5) 96.03%)",
  },
  updatesHeader: {
    gridArea: "updatesHeader",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    borderRight: "1px solid #E3E3E3",
    color: "#fff",
  },
  updatesContent: {
    gridArea: "updatesContent",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Alfa = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { tv } = useSelector((state) => ({ ...state }));

  const [previousSilver, setPreviousSilver] = useState();
  const [previousGold, setPreviousGold] = useState();
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [time, setTime] = React.useState(new Date());
  const [sgd2inr, setSgd2inr] = React.useState(0);
  const [gbp2inr, setGbp2inr] = React.useState(0);
  const [eur2inr, setEur2inr] = React.useState(0);
  const [aed2inr, setAed2inr] = React.useState(0);
  const [usd2inr, setUsd2inr] = React.useState(0);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [silver, setSilver] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });

  const [gold, setGold] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });

  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setIsMarketOpen(data.data?.isMarketOpen);
        setPreviousGold(gold);
        setGold({
          ask: data.data.ask,
          bid: data.data.bid,
          highPrice: data.data.highPrice,
          lowPrice: data.data.lowPrice,
        });
      });
      socket.on("silver-rate-change", (data) => {
        setPreviousSilver(silver);
        setSilver({
          ask: data.data.ask,
          bid: data.data.bid,
          highPrice: data.data.highPrice,
          lowPrice: data.data.lowPrice,
        });
      });
    }
  });

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
  }, []);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;

        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerExpire();
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 20 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handleFindRatio();
  }, []);

  useEffect(() => {
    handelFindBanners();
  }, []);

  useEffect(() => {
    handleloginChecking();
  }, []);

  useEffect(() => {
    hadlefetchNews();
  }, []);

  useEffect(() => {
    handleFindSpread();
  }, []);

  useEffect(() => {
    handleFindLiveValueTypeForDisplay();
  }, []);

  useEffect(() => {
    handlecheckSubscriptionExpierd();
  }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  useEffect(() => {
    getUsdCurrencyRate();
    handleFinsCommodities();
    getEurCurrencyRate();
    getEurCurrencyRate();
    getSgdCurrencyRate();
    getAedCurrencyRate();
    getGbpCurrencyRate();
  }, []);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res) {
      setRatio(res);
    }
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      if (!socket) {
        socket = io(res?.socketURL);
        Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
      }
    }
  };

  const handleloginChecking = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await loginChecking(deviceId);
    if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
      setOpenlimitExpierd(true);
    } else if (res.status === false) {
      Cookies.remove("tv");
      Cookies.remove("deviceId");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  const handleLogout = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await logoutTv({
      adminId: adminId,
      deviceId: deviceId,
    });
    if (res) {
      Cookies.remove("tv");
      Cookies.remove("deviceId");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handlecheckSubscriptionExpierd = () => {
    const givenDate = new Date(tv?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();

    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  async function triggerExpire() {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();

    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  }

  const formatTime = (date) => {
    return date.toLocaleTimeString(["en-US"], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatDate = (date) => {
    return date.toLocaleString([], {
      day: "numeric",
      month: "long",
    });
  };

  const formatYear = (date) => {
    return date.toLocaleString([], {
      year: "numeric",
    });
  };

  const formatDay = (date) => {
    return date.toLocaleString([], { weekday: "long" });
  };

  const getUsdCurrencyRate = async () => {
    const res = await getConversionValue("USD");
    if (res) {
      setUsd2inr(res.conversion_rates.INR);
    }
  };

  const getAedCurrencyRate = async () => {
    const res = await getConversionValue("AED");
    if (res) {
      setAed2inr(res.conversion_rates.INR);
    }
  };

  const getEurCurrencyRate = async () => {
    const res = await getConversionValue("EUR");
    if (res) {
      setEur2inr(res.conversion_rates.INR);
    }
  };

  const getGbpCurrencyRate = async () => {
    const res = await getConversionValue("GBP");
    if (res) {
      setGbp2inr(res.conversion_rates.INR);
    }
  };

  const getSgdCurrencyRate = async () => {
    const res = await getConversionValue("SGD");
    if (res) {
      setSgd2inr(res.conversion_rates.INR);
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const buyersColor = "#2BA817";
  const sellersColor = "#DE5B56";
  const progressStyle = {
    backgroundColor: sellersColor,
  };
  const barStyle = {
    backgroundColor: buyersColor,
  };

  return (
    <div
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      <Box className={classes.background}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "-125px",
                right: "0",
                bottom: "0",
                width: "895px",
                height: "531px",
                overflow: "hidden",
                zIndex: "99",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "10%",
                  transform: "rotate(-35deg)",
                  display: "flex",
                  // justifyContent: "center",
                  backgroundColor: "#fff",
                  color: "#000",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  padding: "20px",
                  lineHeight: 1.5,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Marquee delay="2">
                  {remainingTime && (
                    <p style={{ marginLeft: "90px" }}>
                      Market closed! Opens on{" "}
                      {formatRemainingTime(remainingTime)}
                    </p>
                  )}
                </Marquee>
              </div>
            </Box>
          )}
          <Box className={classes.header}>
            <Box
              sx={{
                display: "flex",
                height: "15vh",
                alignItems: "flex-end",
                gap: "1rem",
              }}
            >
              <img
                className={classes.alfaLogoImg}
                src={alfaLogo}
                alt="alfa Logo"
              />
              <Typography sx={{ color: "#fff", fontSize: "1.4vw", pb: "1rem" }}>
                +971 43866653
              </Typography>
              <Typography sx={{ color: "#fff", fontSize: "1.4vw", pb: "1rem" }}>
                +971 42249677
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#fff",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "2.2vw",
                }}
              >
                {formatTime(time)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  mt: -1,
                }}
              >
                <Typography sx={{ fontSize: "1.8vw" }}>
                  {formatDate(time)}
                </Typography>
                <Typography sx={{ fontSize: "1.4vw" }}>
                  {formatYear(time)},
                </Typography>
                <Typography sx={{ fontSize: "1.4vw" }}>
                  {formatDay(time)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ borderTop: "1px solid #E3E3E3" }}></Box>

          <Box className={classes.body}>
            <Box className={classes.leftPart}>
              <Box className={classes.table}>
                <Box className={classes.tableHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      paddingLeft: 4,
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Commodity
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      paddingLeft: 2,
                      // background: "white",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Weight
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      paddingLeft: 4,
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Buy
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      paddingLeft: 4,
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Sell
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box key={idx} className={classes.tableRow1}>
                        <Typography
                          style={{ fontSize: "1.5vw", fontWeight: "bold" }}
                          className={classes.tableRowColumn}
                        >
                          {words.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {word}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>
                        <Typography
                          style={{
                            padding: 0,
                            fontSize: "2vw",
                            fontWeight: "bold",

                            justifyContent: "center",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {commodity.unit}
                          {commodity.unitLabel}
                        </Typography>
                        <Typography
                          style={{ fontSize: "2vw", fontWeight: "bold" }}
                          className={classes.tableRowColumn}
                        >
                          {commodityCalculation(
                            commodity.commodity_title === "Silver"
                              ? silver.bid
                              : gold.bid,
                            commodity.commodity_title === "Silver"
                              ? spread.silverBidSpread
                              : spread.goldBidSpread,
                            commodity.buy_premium,
                            3.674,
                            commodity.purity,
                            commodity.unit,
                            commodity.unitLabel === "TTB"
                              ? 116.64
                              : commodity.unitLabel === "KG"
                              ? 1000
                              : commodity.unitLabel === "OZ"
                              ? 31.1
                              : commodity.unitLabel === "TOLA"
                              ? 11.7
                              : 1,
                            commodity.buy_charge
                          )}
                        </Typography>
                        <Typography
                          style={{ fontSize: "2vw", fontWeight: "bold" }}
                          className={classes.tableRowColumn}
                        >
                          {commodityCalculation(
                            commodity.commodity_title === "Silver"
                              ? silver.ask
                              : gold.ask,
                            commodity.commodity_title === "Silver"
                              ? spread.silverAskSpread
                              : spread.goldAskSpread,
                            commodity.premium,
                            3.674,
                            commodity.purity,
                            commodity.unit,
                            commodity.unitLabel === "TTB"
                              ? 116.64
                              : commodity.unitLabel === "KG"
                              ? 1000
                              : commodity.unitLabel === "OZ"
                              ? 31.1
                              : commodity.unitLabel === "TOLA"
                              ? 11.7
                              : 1,
                            commodity.charges
                          )}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>

              <Box className={classes.lowerPart}>
                <Box className={classes.stats}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      px: 5,
                      py: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.3vw" }}>BUYERS</Typography>
                    <Typography
                      sx={{
                        color: "#DE5B56",
                        fontSize: "1.3vw",
                        color:
                          Number(ratio?.chgValue?.replace("%", "")) >= 0
                            ? "#2BA817"
                            : "#DE5B56",
                      }}
                    >
                      {ratio?.chgValue}
                    </Typography>
                    <Typography sx={{ fontSize: "1.3vw" }}>SELLERS</Typography>
                  </Box>
                  <Box sx={{ px: 4 }}>
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      style={progressStyle}
                      sx={{
                        "& .MuiLinearProgress-bar": barStyle,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      px: 7,
                      py: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.3vw", color: "#2BA817" }}>
                      {ratio?.Buyers}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <a href="https://www.bullionstats.com/">
                        <img
                          className={classes.bullionStatsImg}
                          src={bullionStats}
                          alt="bullionStats"
                        />
                      </a>
                    </Box>
                    <Typography sx={{ fontSize: "1.3vw", color: "#DE5B56" }}>
                      {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.banner}>
                  <Carousel
                    animation="fade" // Set the animation type to slide
                    navButtonsAlwaysVisible={false} // Show navigation buttons always
                    interval={10000}
                    indicatorContainerProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    sx={{
                      height: "18vh",
                      borderRadius: "20px",
                    }}
                    transitionTime={3000} // Adjust the transition time to control the speed of the fade effect (in milliseconds)
                    autoPlay
                    duration={2000}
                  >
                    {banners?.map((banner, i) => (
                      <img
                        style={{
                          width: "100%",
                          height: "18vh",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        src={banner.imageUrl}
                        alt="banner"
                      />
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Box>

            <Box className={classes.rightPart}>
              <Box className={classes.spotRate}>
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    // px: 2,
                    height: "23%",
                    borderBottom: "1px solid #E3E3E3",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.4vw",
                      fontWeight: "bold",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    SPOT RATE
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        color: "#000",
                        background: "#fff",
                        px: "7px",
                        display: "inline-block",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1vw", fontWeight: "bold" }}
                      >
                        $
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                      {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                    </Typography>
                    <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        color: "#000",
                        background: "#fff",
                        px: "7px",
                        display: "inline-block",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1vw", fontWeight: "bold" }}
                      >
                        $
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                      {displayBidOrBuy?.askOrSell?.toUpperCase()}
                    </Typography>
                    <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "42%",
                    display: "flex",
                    // justifyContent: "space-around",
                    // px: 5,
                    // gap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.8vw",
                      fontWeight: "bold",
                      marginBottom: 4,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    GOLD
                  </Typography>

                  <Box className={classes.spotRateBoxGoldRow2Col1}>
                    <Box
                      sx={{
                        background:
                          gold?.bid < previousGold?.bid
                            ? "#DE5B56"
                            : gold?.bid > previousGold?.bid
                            ? "#5FA147"
                            : "",
                        // : "#2F2E2C",
                        border: "1px solid #fff",
                        color: "#fff",
                        borderRadius: "5px",
                        // display: "inline-block",
                        // width: "fit-content",
                        width: "7.3vw",
                        height: "6.3vh",
                        display: "flex",
                        alignItems: "center",
                        px: 1.5,
                      }}
                    >
                      <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                        {(
                          Number(spread.goldBidSpread) + Number(gold.bid)
                        ).toFixed(2)}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        // pl: 1,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#DE5B56",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                      </Box>
                      <Typography style={{ fontSize: "1.3vw" }}>
                        {(
                          Number(spread.goldLowSpread) + Number(gold.lowPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.spotRateBoxGoldRow2Col2}>
                    <Box
                      sx={{
                        background:
                          gold?.ask < previousGold?.ask
                            ? "#DE5B56"
                            : gold?.bid > previousGold?.bid
                            ? "#5FA147"
                            : "",
                        border: "1px solid #fff",
                        color: "#fff",
                        borderRadius: "5px",
                        // display: "inline-block",
                        // width: "fit-content",
                        width: "7.3vw",
                        height: "6.3vh",
                        display: "flex",
                        alignItems: "center",
                        px: 1.5,
                      }}
                    >
                      <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                        {(
                          Number(spread.goldAskSpread) + Number(gold.ask)
                        ).toFixed(2)}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        // pl: 1,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#5FA147",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>
                          High
                        </Typography>
                      </Box>
                      <Typography style={{ fontSize: "1.3vw" }}>
                        {(
                          Number(spread.goldHighSpread) + Number(gold.highPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "42%",
                    display: "flex",
                    // px: 5,
                    // gap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.6vw",
                      marginBottom: 4,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    SILVER
                  </Typography>

                  <Box className={classes.spotRateBoxSilverRow2Col1}>
                    <Box
                      sx={{
                        background:
                          silver?.bid < previousSilver?.bid
                            ? "#DE5B56"
                            : silver?.bid > previousSilver?.bid
                            ? "#5FA147"
                            : "",
                        border: "1px solid #fff",
                        color: "#fff",
                        borderRadius: "5px",
                        px: 1.5,
                        // display: "inline-block",
                        // width: "fit-content",
                        width: "7.3vw",
                        height: "5.3vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.6vw" }}>
                        &nbsp;&nbsp;
                        {(
                          Number(spread.silverAskSpread) + Number(silver.bid)
                        ).toFixed(2)}
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          background: "#DE5B56",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                      </Box>
                      <Typography style={{ fontSize: "1vw" }}>
                        {(
                          Number(spread.silverLowSpread) +
                          Number(silver.lowPrice)
                        ).toFixed(2)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.spotRateBoxSilverRow2Col2}>
                    <Box
                      sx={{
                        background:
                          silver?.ask < previousSilver?.ask
                            ? "#DE5B56"
                            : silver?.ask > previousSilver?.ask
                            ? "#5FA147"
                            : "",
                        border: "1px solid #fff",
                        color: "#fff",
                        borderRadius: "5px",
                        px: 1.5,
                        // display: "inline-block",
                        // width: "fit-content",
                        width: "7.3vw",
                        height: "5.3vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.6vw" }}>
                        &nbsp;&nbsp;
                        {(
                          Number(spread.silverBidSpread) + Number(silver.ask)
                        ).toFixed(2)}
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          background: "#5FA147",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>
                          High
                        </Typography>
                      </Box>
                      <Typography style={{ fontSize: "1vw" }}>
                        {(
                          Number(spread.silverHighSpread) +
                          Number(silver.highPrice)
                        ).toFixed(2)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.currencyConverter}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mx: "1rem",
                    py: "0.5rem",
                    borderBottom: "1px solid #E3E3E3",
                    flexBasis: 0,
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={usFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          // flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            // mt: -0.8,
                          }}
                        >
                          1&nbsp;
                        </Typography>
                        <Typography sx={{ fontSize: "2.3vh" }}>USD</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        bgcolor: "#fff",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "4vh",
                        height: "4vh",
                      }}
                    >
                      <SwapHorizIcon sx={{ fontSize: "20px" }} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography sx={{ fontSize: "1.8vh" }}>INR</Typography>
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            mt: -0.8,
                          }}
                        >
                          {/* {aed.toFixed(2)}&nbsp;&nbsp; */}
                          {usd2inr.toFixed(2)}&nbsp;&nbsp;
                        </Typography>
                      </Box>
                      <img
                        src={indFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mx: "1rem",
                    py: "0.5rem",
                    borderBottom: "1px solid #E3E3E3",
                    flexBasis: 0,
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={uaeFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          // flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            // mt: -0.8,
                          }}
                        >
                          1&nbsp;
                        </Typography>
                        <Typography sx={{ fontSize: "2.3vh" }}>AED</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        bgcolor: "#fff",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "4vh",
                        height: "4vh",
                      }}
                    >
                      <SwapHorizIcon sx={{ fontSize: "20px" }} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography sx={{ fontSize: "1.8vh" }}>INR</Typography>
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            mt: -0.8,
                          }}
                        >
                          {/* {inr.toFixed(2)} */}
                          {aed2inr.toFixed(2)}&nbsp;&nbsp;
                        </Typography>
                      </Box>
                      <img
                        src={indFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mx: "1rem",
                    py: "0.5rem",
                    borderBottom: "1px solid #E3E3E3",
                    flexBasis: 0,
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={euroFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          // flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            // mt: -0.8,
                          }}
                        >
                          1&nbsp;
                        </Typography>
                        <Typography sx={{ fontSize: "2.3vh" }}>EUR</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        bgcolor: "#fff",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "4vh",
                        height: "4vh",
                      }}
                    >
                      <SwapHorizIcon sx={{ fontSize: "20px" }} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography sx={{ fontSize: "1.8vh" }}>INR</Typography>
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            mt: -0.8,
                          }}
                        >
                          {/* {eur.toFixed(2)}&nbsp;&nbsp; */}
                          {eur2inr.toFixed(2)}&nbsp;&nbsp;
                        </Typography>
                      </Box>
                      <img
                        src={indFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mx: "1rem",
                    py: "0.5rem",
                    borderBottom: "1px solid #E3E3E3",
                    flexBasis: 0,
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={gbpFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          // flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            // mt: -0.8,
                          }}
                        >
                          1&nbsp;
                        </Typography>
                        <Typography sx={{ fontSize: "2.3vh" }}>GBP</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        bgcolor: "#fff",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "4vh",
                        height: "4vh",
                      }}
                    >
                      <SwapHorizIcon sx={{ fontSize: "20px" }} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography sx={{ fontSize: "1.8vh" }}>INR</Typography>
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            mt: -0.8,
                          }}
                        >
                          {/* {gbp.toFixed(2)}&nbsp;&nbsp; */}
                          {gbp2inr.toFixed(2)}
                        </Typography>
                      </Box>
                      <img
                        src={indFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mx: "1rem",
                    py: "0.5rem",
                    // borderBottom: "1px solid #E3E3E3",
                    flexBasis: 0,
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={sgdFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          // flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            // mt: -0.8,
                          }}
                        >
                          1&nbsp;
                        </Typography>
                        <Typography sx={{ fontSize: "2.3vh" }}>SGD</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        bgcolor: "#fff",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "4vh",
                        height: "4vh",
                      }}
                    >
                      <SwapHorizIcon sx={{ fontSize: "20px" }} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <Typography sx={{ fontSize: "1.8vh" }}>INR</Typography>
                        <Typography
                          sx={{
                            fontSize: "2.3vh",
                            fontWeight: "bold",
                            mt: -0.8,
                          }}
                        >
                          {/* {eur.toFixed(2)}&nbsp;&nbsp; */}
                          {sgd2inr.toFixed(2)}&nbsp;&nbsp;
                        </Typography>
                      </Box>
                      <img
                        src={indFlag}
                        alt="us"
                        style={{ width: "5vh", height: "5vh" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.updates}>
            <Box className={classes.updatesHeader}>
              <Typography sx={{ fontSize: "1.5vw", fontWeight: "bold" }}>
                ALFA
              </Typography>
              &nbsp;
              <Typography sx={{ fontSize: "1.5vw" }}>Updates</Typography>
            </Box>
            <Box className={classes.updatesContent}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.4vw",
                }}
              >
                {news?.map((obj, i) =>
                  i === news.length - 1 ? (
                    <Marquee delay="2" key={i}>
                      {obj.newsDetails}&nbsp;
                    </Marquee>
                  ) : null
                )}
              </Typography>
              <LogoutIcon
                sx={{
                  fontSize: "40px",
                  color: "white",
                  cursor: "pointer",
                  alignSelf: "self-end",
                  my: "auto",
                }}
                onClick={handleLogout}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openlimitExpierd}
        closeAfterTransition
      >
        <Fade in={openlimitExpierd}>
          <Box>
            <LimitExceededModal logout={handleLogout} />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openSubscription}>
          <Box>
            <SubscriptionExpiringSoon
              date={tv?.package.expire_date}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired handleClose={handleLogout} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Alfa;
