import { Box, Button, Typography } from "@mui/material";
import Moment from "react-moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 6,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
};
export default function SubscriptionExpiringSoon({ handleClose, date }) {
  return (
    <Box sx={style}>
      <Typography
        variant="h4"
        sx={{ color: "red", fontFamily: "poppins", fontSize: 30 }}
      >
        Your Subscription Expiring Soon
      </Typography>
      <Typography
        sx={{
          mt: 2,
          fontSize: 15,
          fontFamily: "poppins",
          textAlign: "center",
          width: 400,
        }}
      >
        Your subscription will be expiring on &nbsp;
        <span>
          <Moment element="span" style={{ width: "100%" }} format="MMM Do YYYY">
            {date}
          </Moment>
        </span>
        . Please subscribe to continue our service
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 4,
          width: "100px",
          textTransform: "none",
          background: "3DA1EA",
          "&:hover": {
            backgroundColor: "3DA1EA",
          },
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
    </Box>
  );
}
