import axios from "axios";

const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;
console.log(adminId);

export const tvLoging = async (value) => {
  console.log(value);
  try {
    const { data } = await axios.post(
      `${host}/loginWithDeviceDetailes`,
      value,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const loginChecking = async (deviceId) => {
  try {
    const { data } = await axios.get(
      `${host}/checkUserIsExist?adminId=${adminId}&deviceId=${deviceId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const logoutTv = async (value) => {
  try {
    const { data } = await axios.post(`${host}/tvLogoutWithDeviceId`, value, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
